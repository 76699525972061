import React from "react";
import { Button } from "@mantine/core";

function AppButton({ label, ...rest }: any) {
	return (
		<Button color="#313131" {...rest}>
			{label}
		</Button>
	);
}

export default AppButton;
