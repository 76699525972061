import React from "react";
import ReactDOM from "react-dom/client";

import "@coreui/coreui/dist/css/coreui.min.css";
// import "rsuite/dist/rsuite-no-reset.min.css";
import "rsuite/dist/rsuite.min.css";
import "@mantine/core/styles.css";
import "./sass/main.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Redux
import { Provider } from "react-redux";
import { store } from "./app/store";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
