import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SignupForm, { IFormState } from "./SignupForm";
import { signup } from "../../../api/endpoints/authApi";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectAuth, setCredentials } from "../../../features/authSlice";
import BusinessForm from "../../../components/forms/BusinessForm";

function Signup() {
	const { user, isAuthenticated } = useAppSelector(selectAuth);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	let location = useLocation();
	let from = location.state?.from?.pathname || "/dashboard";

	const [formState, setFormState] = useState({
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		confirmPassword: "",
	});

	useEffect(() => {
		if (user && isAuthenticated) {
			navigate(from, { replace: true });
		}
	}, [isAuthenticated]);

	// const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	const { name, value } = e.target;
	// 	setFormState({ ...formState, [name]: value });
	// };

	const handleSignup = async (values: IFormState) => {
		setIsLoading(true);

		// toast.success("Testing success");

		try {
			const response = await signup(values);
			console.log("Signup up response", response);

			if (response.success) {
				dispatch(setCredentials(response.data));
				navigate("dashboard");
			}
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="signup__page">
			<div className="content">
				<h2 className="heading">Create Account</h2>
				{/* <SignupForm
					state={formState}
					loading={isLoading}
					handleSubmit={handleSignup}
				/> */}
				<BusinessForm />
			</div>
			<div className="showcase"></div>
		</div>
	);
}

export default Signup;
