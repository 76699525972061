import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Checkbox, PasswordInput, Text, TextInput, Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import AppButton from "../../../components/buttons/AppButton";
import { validateLoginForm } from "../../../utils";

export interface IFormState {
	email: string;
	password: string;
}

type Props = {
	state: IFormState;
	loading: boolean;
	handleSubmit: (obj: IFormState) => void;
};

const styles = {
	label: {
		fontWeight: 400,
		// marginBottom: 5,
	},
};

function LoginForm({ state, loading, handleSubmit }: Props) {
	const [rememberMeChecked, setRememberMeChecked] = useState(false);

	const toggleRememberMe = () => {
		setRememberMeChecked(!rememberMeChecked);
		localStorage.setItem("persist", JSON.stringify(!rememberMeChecked));
	};

	const form = useForm({
		initialValues: state,
		validate: {
			email: (value) => validateLoginForm("email", value),
			password: (value) => validateLoginForm("password", value),
		},
	});

	return (
		<form
			onSubmit={form.onSubmit(async (values) => handleSubmit(values))}
			className="login__form"
		>
			<div className="input_field-container">
				<TextInput
					value={state.email}
					name="email"
					label="Email"
					placeholder="e.g. mary@business.com"
					{...form.getInputProps("email")}
					styles={styles}
				/>
				<PasswordInput
					label="Password"
					name="password"
					value={state.password}
					placeholder="Password"
					styles={styles}
					{...form.getInputProps("password")}
				/>
			</div>

			<Flex align="end" justify="space-between">
				<Checkbox
					mt="sm"
					label="Remember me"
					checked={rememberMeChecked}
					onChange={toggleRememberMe}
					// {...form.getInputProps("termsOfService", { type: "checkbox" })}
				/>

				<Link to="/forgot-password" className="forgot_password-link">
					Forgot password?
				</Link>
			</Flex>

			<AppButton
				variant="filled"
				loading={loading}
				mt="30"
				fullWidth
				type="submit"
				label="Login"
				styles={{
					root: {
						textTransform: "uppercase",
						borderRadius: 20,
						height: 48,
					},
				}}
			/>

			<Text className="signup_link_text">
				Don't have an account? <Link to="/become-a-partner">Sign up</Link>
			</Text>
		</form>
	);
}

export default LoginForm;
