import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
	header: string;
	message: string;
	isOpen: boolean;
	type: "info" | "success" | "warning" | "error" | undefined;
}

const initialState: InitialState = {
	header: "",
	message: "",
	isOpen: false,
	type: undefined,
};

const notificationSlice = createSlice({
	name: "notification",
	initialState,
	reducers: {
		showNotification: (state, action) => {
			const { payload } = action;
			state.header = payload.header;
			state.message = payload.message;
			state.type = payload.type;
			state.isOpen = true;
		},

		hideNotification: () => {
			return initialState;
		},
	},
});

const slice = notificationSlice;

export const { showNotification, hideNotification } = slice.actions;

export const selectNotification = (state: any) => state.notification;

export default slice.reducer;
