import React, { useState } from "react";
import AppButton from "../buttons/AppButton";
import { Flex, PasswordInput, Text, TextInput } from "@mantine/core";
import { useForm, isEmail, isNotEmpty } from "@mantine/form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { registerBusiness } from "../../api/endpoints/authApi";
import { checkPasswordMatch } from "../../utils";
import { useAppDispatch } from "../../hooks";
import { setCredentials } from "../../features/authSlice";

interface Business {
	store_name: string;
	address_1: string;
	address_2: string;
	city: string;
	state: string;
	zip: string;
	first_name: string;
	last_name: string;
	phone_number: string;
	email: string;
	cuisine_type: string;
	num_of_locations: string;
	password: string;
	confirmPassword: string;
}

const styles = {
	label: {
		// fontSize: 15,
		fontWeight: 400,
	},
};

const inputFields = [
	{ label: "Store name", name: "store_name", placeholder: "" },
	{ label: "Address", name: "address_1", placeholder: "Enter store address" },
	{ label: "Apt, Suite, etc. (Optional)", name: "address_2", placeholder: "" },
	{ label: "City", name: "city", placeholder: "" },
	{ label: "State", name: "state", placeholder: "" },
	{ label: "Zip", name: "zip", placeholder: "" },
	{ label: "Cuisine Type", name: "cuisine_type", placeholder: "" },
	{ label: "Number of locations", name: "num_of_locations", placeholder: "" },

	{ label: "First Name", name: "first_name", placeholder: "" },
	{ label: "Last Name", name: "last_name", placeholder: "" },
	{ label: "Email", name: "email", placeholder: "" },
	{ label: "Phone number", name: "phone_number", placeholder: "" },
	{ label: "Password", name: "password", placeholder: "" },
	{ label: "Confirm Password", name: "confirmPassword", placeholder: "" },
];

const INPUT_FILTER = ["first_name", "last_name", "city", "state", "zip"];

function BusinessForm() {
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	let location = useLocation();

	let from = location.state?.from?.pathname || "/dashboard";

	const state: Business = {
		store_name: "",
		address_1: "",
		address_2: "",
		city: "",
		state: "",
		zip: "",
		first_name: "",
		last_name: "",
		phone_number: "",
		email: "",
		cuisine_type: "",
		num_of_locations: "",
		password: "",
		confirmPassword: "",
	};

	const form = useForm({
		initialValues: state,
		validate: {
			store_name: isNotEmpty("Store name is required"),
			address_1: isNotEmpty("Store address is required"),
			city: isNotEmpty("City is required"),
			state: isNotEmpty("State is required"),
			zip: isNotEmpty("Zip is required"),
			first_name: isNotEmpty("First name required"),
			last_name: isNotEmpty("Last name required"),
			phone_number: isNotEmpty("Phone number is required"),
			email: isEmail("Please enter a valid email address"),
			// Password validation ideas
			// Include uppercase and lowercase letter, include number,
			// include a special character: #.-?!@$%^&*
			password: isNotEmpty("You must enter a password"),
			confirmPassword: (value, values) =>
				checkPasswordMatch(values.password, value),
		},
	});

	const handleSubmit = async (values: Business) => {
		setLoading(true);

		const res = await registerBusiness(values);
		console.log("business form submission res", res);

		if (res.success) {
			// success
			dispatch(setCredentials(res.data));
			navigate(from, { replace: true });
			setLoading(false);
		} else {
			// error
			form.setErrors({ email: res.data.message });
			setLoading(false);
		}

		setLoading(false);
	};

	return (
		<form
			onSubmit={form.onSubmit(async (values) => handleSubmit(values))}
			className="business-form"
		>
			<div className="input_field-container">
				{/* <Flex gap="xs" justify="center" align="center"> */}

				{inputFields.map((item) => {
					if (!INPUT_FILTER.includes(item.name)) {
						const isPasswordField =
							item.name === "password" ||
							item.name === "confirmPassword";

						return isPasswordField ? (
							<PasswordInput
								styles={styles}
								label={item.label}
								name={item.name}
								placeholder={item.placeholder}
								{...form.getInputProps(item.name)}
							/>
						) : (
							<TextInput
								styles={styles}
								label={item.label}
								name={item.name}
								placeholder={item.placeholder}
								{...form.getInputProps(item.name)}
							/>
						);
					} else {
						return null;
					}
				})}

				<Flex gap="xs" justify="space-between">
					{inputFields.map((item) => {
						if (
							item.name === "city" ||
							item.name === "state" ||
							item.name === "zip"
						) {
							return (
								<TextInput
									styles={styles}
									label={item.label}
									name={item.name}
									placeholder={item.placeholder}
									{...form.getInputProps(item.name)}
								/>
							);
						} else {
							return null;
						}
					})}
				</Flex>

				<Flex gap="xs" justify="space-between">
					{inputFields.map((item) => {
						if (item.name === "first_name" || item.name === "last_name") {
							return (
								<TextInput
									styles={styles}
									label={item.label}
									name={item.name}
									placeholder={item.placeholder}
									{...form.getInputProps(item.name)}
								/>
							);
						} else {
							return null;
						}
					})}
				</Flex>

				{/* <Flex gap="xs">
					{cityStateZipInputFields.map((item) => {
						return (
							<TextInput
								styles={styles}
								label={item.label}
								name={item.name}
								placeholder={item.placeholder}
								{...form.getInputProps(item.name)}
							/>
						);
					})}
				</Flex> */}

				{/* <TextInput
						label="Last Name"
						name="lastName"
						value={state.last_name}
						styles={styles}
						placeholder="Last Name"
						{...form.getInputProps("lastName")}
					/>
				</Flex>

				<TextInput
					value={state.email}
					name="email"
					label="Email"
					placeholder="email@business.com"
					styles={styles}
					{...form.getInputProps("email")}
				/> */}
			</div>

			<div className="terms_of_agreement_wrapper">
				<Text span className="terms_of_agreement_text">
					By clicking "Continue", you agree to our <Link to="">terms</Link>{" "}
					and acknowledge our <Link to="">Privacy Policy</Link>
				</Text>
			</div>

			<AppButton
				variant="filled"
				loading={loading}
				mt="md"
				fullWidth
				type="submit"
				label="Continue"
				rightSection={<FaArrowRightLong />}
				styles={{
					root: {
						textTransform: "uppercase",
						borderRadius: 20,
						height: 48,
					},
				}}
			/>

			<Text className="login_link_text">
				Already have an account? <Link to="/login">Login</Link>
			</Text>
		</form>
	);
}

export default BusinessForm;
