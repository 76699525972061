import React from "react";
import { IoFastFoodSharp } from "react-icons/io5";

function EmptyTable({ isLoading }: { isLoading: boolean }) {
	if (isLoading) {
		return null;
	}

	return (
		<div className="empty-menu-table">
			<IoFastFoodSharp size={50} />
			<div className="text-wrapper">
				<h6>Your menu is empty</h6>
				<p>Start adding some items!</p>
			</div>
		</div>
	);
}

export default EmptyTable;
