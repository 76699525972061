import React from "react";
import { Toggle, Table } from "rsuite";

const { Cell } = Table;

const ToggleCell = ({ rowData, shown, toggle, dataKey, ...props }: any) => {
	const handleClick = (checked: any) => {
		toggle(rowData.id, checked);
	};

	return (
		<Cell
			{...props}
			style={{
				padding: "6px",
				display: "flex",
				gap: "4px",
				alignItems: "center",
			}}
		>
			<Toggle checked={rowData.isVisible} onChange={handleClick} />
		</Cell>
	);
};

export default ToggleCell;
