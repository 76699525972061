export function validateSignupForm(field: string, value: string) {
	if (field === "firstName") {
		if (value === "" || !value) {
			return "Please enter first name";
		}
	}

	if (field === "lastName") {
		if (value === "" || !value) {
			return "Please enter last name";
		}
	}

	if (field === "email") {
		if (value === "" || !value) {
			return "Please enter a valid email address";
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			return "Invalid email address";
		}
	}

	if (field === "password") {
		if (value === "" || !value) {
			return "Please enter a valid password";
		}

		// else if (value.length < 8) {
		// 	return "Password must have at least 8 characters.";
		// }
	}

	return null;
}

export function validateLoginForm(field: string, value: string) {
	if (field === "email") {
		if (value === "") {
			return "Please enter a valid email address.";
		}

		if (!/^\S+@\S+$/.test(value)) {
			return "Invalid email";
		}

		if (!value) {
			return "Email required";
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			return "Invalid email address";
		}
	}

	if (field === "password") {
		if (!value) {
			return "Password is required.";
		}
	}

	return null;
}

export const saveToStorage = (key: string, value: string) => {
	localStorage.setItem(key, value);
};

export const getStorageItem = (key: string) => {
	return localStorage.getItem(key);
};

export const saveAccessToken = (token: string) => {
	localStorage.setItem("access-token", token);
};

export const saveRefreshToken = (token: string) => {
	localStorage.setItem("refresh-token", token);
};

export const getAccessToken = () => {
	return localStorage.getItem("access-token");
};

export const getRefreshToken = () => {
	return localStorage.getItem("refresh-token");
};

export const clearStorage = () => {
	localStorage.clear();
};

export function checkPasswordMatch(password: string, value: string) {
	if (value === "" || !value) {
		return "Please re-enter password";
	}

	if (password !== value) {
		return "Passwords do not match.";
	}

	return null;
}
