import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoginForm, { IFormState } from "./LoginForm";
import { login } from "../../../api/endpoints/authApi";
import { setCredentials } from "../../../features/authSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectAuth } from "../../../features/authSlice";
import Copyright from "../../../components/Copyright";
import { useForm } from "@mantine/form";
import useNotification from "../../../hooks/useNotification";

function Login() {
	const { user, isAuthenticated } = useAppSelector(selectAuth);
	const { trigger } = useNotification();
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	let location = useLocation();
	const form = useForm();

	let from = location.state?.from?.pathname || "/dashboard";

	const [formState, setFormState] = useState({
		email: "",
		password: "",
	});

	useEffect(() => {
		if (user && isAuthenticated) {
			navigate(from, { replace: true });
		}
	}, [user, isAuthenticated]);

	const handleLogin = async (values: IFormState) => {
		setIsLoading(true);

		const response = await login(values);
		console.log("Login response", response);

		if (response.success) {
			dispatch(setCredentials(response.data));
			navigate(from, { replace: true });
		} else {
			console.error(response);

			trigger({
				type: "error",
				header: "Error",
				message: response.message,
			});

			form.setErrors({ email: response.message });
			setIsLoading(false);
		}

		setIsLoading(false);
	};

	return (
		<div className="login__page">
			<div className="showcase"></div>

			<div className="content">
				{/* <h1 className="business_logo">Flavorfeed</h1> */}
				<h1 className="heading">Welcome back!</h1>
				<LoginForm
					state={formState}
					loading={isLoading}
					handleSubmit={handleLogin}
				/>

				<div className="copyright_wrapper">
					<Copyright sx={{ fontSize: 12 }} />
				</div>
			</div>
		</div>
	);
}

export default Login;
