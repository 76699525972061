import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Text, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { forgotPassword } from "../../api/endpoints/authApi";
import Copyright from "../../components/Copyright";
import AppButton from "../../components/buttons/AppButton";

const styles = {
	label: {
		// fontSize: 24,
		fontWeight: 400,
	},
};

function ForgotPassword() {
	const [state, setState] = useState({ email: "" });
	const [loading, setLoading] = useState(false);

	const form = useForm({
		initialValues: state,
		validate: {
			email: (value) => {
				let error = "";

				if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
					error = "Invalid email";
				}

				if (!value) {
					error = "Email is required";
				}

				if (error !== "") {
					return error;
				} else {
					return null;
				}
			},
		},
	});

	const handleSubmit = async (value: string) => {
		setLoading(true);
		const response = await forgotPassword(value);

		if (response.data.success) {
			// Handle success
		} else {
			let errorMessage = response.data.message;

			if (errorMessage === "User not found") {
				errorMessage = "Sorry, we don't recognize that email address";
				form.setErrors({ email: errorMessage });
			}
		}

		setLoading(false);
	};
	// Sorry, we don't recognize this email.

	return (
		<div className="forgot_password">
			<header className="header">
				<Link className="logo" to="/">
					<Title order={3}>Flavorfeed</Title>
				</Link>
			</header>
			<div className="content">
				<div className="text_container">
					<Title order={1} className="title">
						Forgot Password?
					</Title>
					<Text className="helper-text">
						Enter the email associated with your account
					</Text>
				</div>

				<form
					onSubmit={form.onSubmit(async (value) =>
						handleSubmit(value.email)
					)}
					className="form"
				>
					<TextInput
						value={state}
						name="email"
						label="Email Address"
						placeholder="e.g.john@business.com"
						{...form.getInputProps("email")}
						styles={styles}
					/>

					<Button bg="#212631" fullWidth className="button" type="submit">
						RESET PASSWORD
					</Button>

					{/* <AppButton
						variant="filled"
						loading={loading}
						mt="30"
						fullWidth
						type="submit"
						label="Reset Password"
						styles={{
							root: {
								textTransform: "uppercase",
								borderRadius: 20,
								height: 48,
							},
						}}
					/> */}

					<Link to="/login" className="back_button">
						Back to login
					</Link>
				</form>
			</div>

			<div className="copyright_wrapper">
				<Copyright />
			</div>
		</div>
	);
}

export default ForgotPassword;
