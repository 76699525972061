import { Table, Input, DatePicker, InputNumber } from "rsuite";

const { Cell } = Table;

function toValueString(value: any, dataType: any) {
	return dataType === "date" ? value?.toLocaleDateString() : value;
}

const fieldMap: any = {
	string: Input,
	number: InputNumber,
	date: DatePicker,
};

const EditableCell = ({
	rowData,
	dataType,
	dataKey,
	onChange,
	onEdit,
	...props
}: any) => {
	const editing = rowData.status === "EDIT";

	const Field = fieldMap[dataType];
	const value = rowData[dataKey];
	const text = toValueString(value, dataType);

	return (
		<Cell
			{...props}
			style={{ fontSize: 14 }}
			className={editing ? "table-cell-editing" : ""}
			onDoubleClick={() => {
				onEdit?.(rowData.id);
			}}
		>
			{editing ? (
				<Field
					defaultValue={value}
					onChange={(value: any) => {
						onChange?.(rowData.id, dataKey, value);
					}}
				/>
			) : (
				text
			)}
		</Cell>
	);
};

export default EditableCell;
