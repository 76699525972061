import React, { useEffect, useState } from "react";
import { Panel } from "rsuite";
import DishCard from "../../components/DishCard";
import Categories from "./components/Categories";
import MenuTable from "./components/MenuTable";

import DISH1 from "../../assets/dish_1.jpeg";
import DISH2 from "../../assets/dish2.jpeg";
import ActionButtons from "./components/ActionButtons";

const dishes = [
	{ title: "Pot Roast", desc: "", picture: DISH1 },
	{
		title: "Shoyu Ramen",
		desc: "",
		picture: DISH2,
	},
];

const MOCK_DATA = [
	{
		id: 1,
		name: "Oysters On The Shell",
		description: "Half Dozen 24, Dozen 42",
		serving_amount: 2,
		isVisible: true,
		category: "Appetizers",
	},
	{
		id: 2,
		name: "Chicken Tortilla Soup",
		description: "pico de gallo, avocado, crema, queso blanco",
		serving_amount: 0,
		isVisible: true,
		category: "Appetizers",
	},
	{
		id: 3,
		name: "Truffled Brie Toast",
		description: "cranberry preserve, black truffle, honeycomb, chive",
		serving_amount: 0,
		isVisible: true,
		category: "Appetizers",
	},
	{
		id: 4,
		name: "Jumbo Lump Crab Cake",
		description: "dhsadhsakfj djpaso jd",
		serving_amount: 0,
		isVisible: true,
		category: "Meats",
	},
	{
		id: 5,
		name: "Short Rib Potstickers",
		description: "djsadas jgldkgjgk",
		serving_amount: 0,
		isVisible: true,
		category: "Salads",
	},
];

function stringToLowerCase(str: string) {
	return str.toLowerCase();
}

function MenuManager() {
	const [tabActive, setTabActive] = useState(0);
	const [data, setData] = useState<any[]>([]);
	const [byCategory, setByCategory] = useState<any[]>([]);
	const [isFiltered, setFiltered] = useState(false);

	console.log("DATA => ", data);

	const [categories, setCategories] = useState([
		"All",
		"Appetizers",
		"Salads",
		"Meats",
		"Seafood",
		"Drinks",
	]);

	const handleTabClick = (tab: number) => {
		if (tab === 0) {
			setByCategory([]);
			setFiltered(false);
		}

		const arr = data.filter(
			(item: any) =>
				stringToLowerCase(item.category) ===
				stringToLowerCase(categories[tab])
		);

		setTabActive(tab);
		setByCategory(arr);
		setFiltered(true);
	};

	useEffect(() => {
		if (!data.length) {
			const fetchData = async () => {
				// const res = await getDishes();
				// if (res.success) {
				// 	setData(res.data);
				// }
			};

			fetchData();

			setData(MOCK_DATA);
		}
	}, [data]);

	return (
		<Panel id="menu-manager" header={<ActionButtons />}>
			<div className="content">
				<Categories
					data={categories}
					active={tabActive}
					onClick={handleTabClick}
				/>
				<MenuTable
					data={isFiltered ? byCategory : data}
					setData={setData}
				/>
			</div>
		</Panel>
	);
}

export default MenuManager;
