import React, { useEffect, useState } from "react";
import ContentLayout from "../../components/layouts/ContentLayout";
import { Group, Select, Stack, Tabs, TextInput } from "@mantine/core";
import DataPanel from "../../components/DataPanel";
import StoreHours from "./tabs/StoreHours";
import { getRestaurant } from "../../api/endpoints/restaurantApi";
import AppLoader from "../../components/AppLoader";

function Stores() {
	const [loading, setLoading] = useState(true);
	const [restaurant, setRestaurant] = useState({});

	useEffect(() => {
		const fetchStore = async () => {
			const res = await getRestaurant();
			console.log("restaurant data", res);

			if (res.success) {
				// handle success
				// setRestaurant(res.data[0]);
				setLoading(false);
			}
		};

		fetchStore();
	}, []);

	if (loading) {
		return <AppLoader />;
	}

	return (
		<ContentLayout heading="Store Management">
			<Tabs defaultValue="overview">
				<Tabs.List>
					<Tabs.Tab value="overview">Store Info</Tabs.Tab>
					<Tabs.Tab value="hours">Business Hours</Tabs.Tab>
				</Tabs.List>

				{/* <Tabs.Panel value="overview" p={30}></Tabs.Panel> */}
				<Tabs.Panel value="hours" p="35px 20px">
					<StoreHours />
				</Tabs.Panel>
			</Tabs>
			{/* <DataPanel title="Store Information">
				<Stack>
					<TextInput label="Store name" />
					<TextInput label="Address 1" />
					<TextInput label="Address 2" />
					<Group>
						<TextInput label="City" />
						<TextInput label="State" />
						<TextInput label="Zip" />
					</Group>
					<Select label="Type of cuisine"></Select>
				</Stack>
			</DataPanel> */}
		</ContentLayout>
	);
}

export default Stores;
