import React, { useState } from "react";
import { Drawer, Button } from "rsuite";
import AppButton from "../../../components/buttons/AppButton";
import { Group, Stack, TextInput } from "@mantine/core";
import { IoAddCircle } from "react-icons/io5";

type BackdropState = boolean | "static" | undefined;

function NewCategoryDrawer() {
	const [open, setOpen] = useState(false);
	const [backdrop, setBackdrop] = useState<BackdropState>(true);
	const [category, setCategory] = useState("");
	const [openDrawer, setOpenDrawer] = useState(false);

	console.log(category);

	const handleCreate = async () => {
		try {
			// if (res.success) {
			// 	setFiles({ ...files, dishId: res.data.id });
			// 	if (files.photos.length) {
			// 		uploader.current.start();
			// 	}
			// }
		} catch (err) {
			console.log(err);
		}
		// Save new dish
		console.log("New dish created");
		// setOpen(false)
	};

	return (
		<>
			<button
				type="button"
				className="add-item-button"
				onClick={() => setOpenDrawer(true)}
			>
				<IoAddCircle size={30} color="#979797" />
			</button>

			<Drawer
				size="28rem"
				backdrop={backdrop}
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
			>
				<Drawer.Header>
					<Drawer.Title>New Category</Drawer.Title>
					<Drawer.Actions>
						<Group>
							<Button onClick={() => setOpen(false)}>Cancel</Button>
							<AppButton label="Create" onClick={handleCreate} />
						</Group>
					</Drawer.Actions>
				</Drawer.Header>
				<Drawer.Body>
					<Stack>
						<TextInput
							label="Add new category"
							// placeholder="What's your new diw"
							name="category"
							value={category}
							onChange={(e) => setCategory(e.target.value)}
						/>
					</Stack>
				</Drawer.Body>
			</Drawer>
		</>
	);
}

export default NewCategoryDrawer;
