import { apiInstance } from "..";

export interface User {
	first_name: string;
	last_name: string;
	email: string;
	phone: string;
}

type PasswordUpdatePayload = {
	currentPassword: string;
	password: string;
	confirmPassword: string;
};

export const updatePassword = async (payload: PasswordUpdatePayload) => {
	try {
		const { data } = await apiInstance.patch(
			"/users/change-password",
			payload
		);
		return data;
	} catch (err: any) {
		console.log(err);

		if (err.response) {
			return err.response.data;
		}
	}
};

export const updateUser = async (id: number, payload: User) => {
	try {
		const { data } = await apiInstance.patch(`/merchants/${id}`, payload);
		return data;
	} catch (err: any) {
		console.log(err);

		if (err.response) {
			return err.response.data;
		}
	}
};

export const deleteAccount = async (id: number) => {
	try {
		const { data } = await apiInstance.delete(`/users/${id}`);
		return data;
	} catch (err: any) {
		console.log(err);

		if (err.response) {
			return err.response.data;
		}
	}
};

export const getUserProfile = async (id: number) => {
	try {
		const { data } = await apiInstance.get(`/merchants/${id}/profile`);
		console.log("user profile data", data);
		return data;
	} catch (err: any) {
		console.log(err);
		return err.response;
	}
};
