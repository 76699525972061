import React, { useState } from "react";
import { Notification, useToaster } from "rsuite";

function useNotification() {
	const [notification, setNotification] = useState<any>(null);
	const toaster = useToaster();

	const trigger = (data: any) => {
		if (data) {
			const myHook = (
				<Notification type={data.type} header={data.header} closable>
					<p>{data.message}</p>
				</Notification>
			);

			setNotification(myHook);
			toaster.push(myHook, { placement: "topCenter" });
		}
	};

	return {
		notification,
		trigger,
	};
}

export default useNotification;
