import React, { useEffect, useState } from "react";
import ContentLayout from "../../components/layouts/ContentLayout";
import { Group, Button, Stack, TextInput, Box } from "@mantine/core";
import DataPanel from "../../components/DataPanel";
import { Avatar } from "@mui/material";
import AppButton from "../../components/buttons/AppButton";
import { updateUser, User } from "../../api/endpoints/usersApi";
import { useAppSelector } from "../../hooks";
import { selectAuth } from "../../features/authSlice";
import { getUserProfile } from "../../api/endpoints/usersApi";
import useNotification from "../../hooks/useNotification";
import AppLoader from "../../components/AppLoader";

function Account() {
	const { trigger } = useNotification();
	const { user } = useAppSelector(selectAuth);
	const [isLoading, setIsLoading] = useState(true);

	const [profile, setProfile] = useState<User>({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setProfile({ ...profile, [name]: value });
	};

	const saveChanges = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);

		const response = await updateUser(user.id, profile);

		if (response.success) {
			// handle success
			setIsLoading(false);
			trigger({
				header: "Success",
				message: "Personal information was updated successfully.",
				type: "success",
			});
		} else {
			// handle error
			setIsLoading(false);
			trigger({
				header: "Error",
				message: response.data.message,
				type: "error",
			});
		}
	};

	const handleAccountDelete = async () => {
		// const response = await deleteAccount(user.id);
		// if (response.success) {
		// 	// handle success
		// } else {
		// 	// handle error
		// }
	};

	useEffect(() => {
		const fetchUserProfile = async () => {
			setIsLoading(true);

			const response = await getUserProfile(user.id);

			if (response.success) {
				const { first_name, last_name, email, phone } = response.data;

				const obj = {
					first_name: first_name ?? "",
					last_name: last_name ?? "",
					email: email ?? "",
					phone: phone ?? "",
				};

				setProfile(obj);
			}

			setIsLoading(false);
		};

		fetchUserProfile();
	}, []);

	if (isLoading) {
		return <AppLoader />;
	}

	return (
		<ContentLayout heading="Account">
			<Stack gap={30}>
				<DataPanel title="Personal Information">
					<Box maw={"80%"}>
						<Stack>
							<Group grow>
								<TextInput
									label="First Name"
									type="text"
									name="first_name"
									value={profile.first_name}
									onChange={handleChange}
								/>
								<TextInput
									label="Last Name"
									type="text"
									name="last_name"
									value={profile.last_name}
									onChange={handleChange}
								/>
							</Group>

							<div className="avatar-group">
								<span className="text-sm font-semibold">Photo</span>

								<div className="flex gap-x-4 mt-2 item-center">
									<Avatar />
									<Group>
										<Button variant="default">Change</Button>
										<Button variant="transparent" color="#000">
											Remove
										</Button>
									</Group>
								</div>
							</div>

							<Group grow>
								<TextInput
									label="Email Address"
									type="text"
									name="email"
									value={profile.email}
									onChange={handleChange}
								/>
								<TextInput
									label="Contact Number"
									type="text"
									name="phone"
									value={profile.phone}
									onChange={handleChange}
								/>
							</Group>
							{/* <TextInput label="Role" /> */}
						</Stack>
					</Box>
					<Group justify="flex-end" className="mt-8">
						<AppButton
							label="Save Changes"
							onClick={saveChanges}
							loading={isLoading}
						/>
					</Group>
				</DataPanel>

				<DataPanel style={{ color: "#d1242f" }} title="Delete Account">
					<Button
						bg="#eaeef2b3"
						style={{ borderColor: "#d0d7de", color: "#d1242f80" }}
						onClick={handleAccountDelete}
					>
						Delete My Account
					</Button>
				</DataPanel>
			</Stack>
		</ContentLayout>
	);
}

export default Account;
