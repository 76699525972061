import { apiInstance } from "..";

export const getRestaurant = async () => {
	try {
		const { data } = await apiInstance.get("/stores");
		return data;
	} catch (err: any) {
		console.log(err);
		return err.response;
	}
};
