import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { getAccessToken } from "../utils";
import { useAppSelector } from "../hooks";
import { selectAuth } from "../features/authSlice";

function AuthRedirect() {
	const { isAuthenticated } = useAppSelector(selectAuth);
	const token = getAccessToken();
	let location = useLocation();

	return token && isAuthenticated ? (
		<Navigate to="/dashboard" state={{ from: location }} replace />
	) : (
		<Outlet />
	);
}

export default AuthRedirect;
