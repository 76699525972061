import React from "react";
import NewCategoryDrawer from "./NewCategoryDrawer";

type Props = {
	data: string[];
	active: number;
	onClick: (val: number) => void;
};

function Categories({ data, active, onClick }: Props) {
	return (
		<div className="categories-container">
			<h4>Categories</h4>

			<div className="actions-container">
				<div className="category-list">
					{data.map((item, i) => {
						const isActive = i === active;
						const activeClassname = isActive ? " active" : "";

						return (
							<button
								key={i}
								className={`category-item${activeClassname}`}
								onClick={() => onClick(i)}
							>
								{item}
							</button>
						);
					})}
				</div>

				<NewCategoryDrawer />
			</div>
		</div>
	);
}

export default Categories;
