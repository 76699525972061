import React from "react";
import { Table } from "rsuite";
import { Menu } from "@mantine/core";
import { IoMdMore } from "react-icons/io";

const { Cell } = Table;

const MoreOptions = ({ rowData, edit, deleteItem }: any) => {
	return (
		<Menu shadow="md" position="left-start">
			<Menu.Target>
				<button type="button">
					<IoMdMore size={24} />
				</button>
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Item onClick={() => edit(rowData.id)}>Edit</Menu.Item>
				<Menu.Item color="red" onClick={() => deleteItem(rowData.id)}>
					Delete
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
};

const ActionCell = ({ deleteItem, edit, rowData, dataKey, ...props }: any) => {
	return (
		<Cell
			{...props}
			style={{
				position: "relative",
				padding: "6px",
				display: "flex",
				gap: "4px",
			}}
		>
			<MoreOptions rowData={rowData} edit={edit} deleteItem={deleteItem} />
		</Cell>
	);
};

export default ActionCell;
