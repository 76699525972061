import React, { useState, useRef } from "react";
import { Uploader, Drawer, ButtonToolbar, Button } from "rsuite";
import AppButton from "../../components/buttons/AppButton";
import { Group, Text, Select, Stack, TextInput, Textarea } from "@mantine/core";
import { FaCloudUploadAlt } from "react-icons/fa";
import { createNewItem } from "../../api/endpoints/menuApi";
import { FileType } from "rsuite/esm/Uploader";

type BackdropState = boolean | "static" | undefined;

// interface IFiles {
// 	dishId: number | null;
// 	photos: FileType[];
// }

interface State {
	merchant_id: number;
	name: string;
	category: string;
	description: string;
	// photos: FileType[];
}

function CreateNew() {
	const [open, setOpen] = useState(false);
	const [startUpdate, setStartUpdate] = useState(false);
	const [backdrop, setBackdrop] = useState<BackdropState>(true);
	const uploader = useRef<any>(null);
	const [files, setFiles] = useState<any>([]);

	const [item, setItem] = useState<State>({
		merchant_id: 1,
		name: "",
		category: "",
		description: "",
	});

	console.log(item);

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setItem({ ...item, [name]: value });
	};

	const resetForm = () => {
		if (files.length) {
			uploader.current.start();
		}

		setItem({
			merchant_id: 1,
			name: "",
			category: "",
			description: "",
		});
	};

	const handleCreate = async (e: any) => {
		console.log("files:", e);

		return;

		const formData: any = new FormData();

		formData.append("merchant_id", item.merchant_id);
		formData.append("name", item.name);
		formData.append("category", item.category);
		formData.append("description", item.description);

		for (let photo of files) {
			formData.append("files", photo);
		}

		try {
			const res = await createNewItem(formData);
			console.log("New Item created response", res);

			if (res.success) {
				// handle success
				resetForm();
			} else {
				// handle error
			}
		} catch (err) {
			console.log(err);
		}
		// Save new dish
		console.log("New dish created");
		// setOpen(false)
	};

	const startUpload = () => {
		uploader.current.start();
	};

	const handleFile = async (value: any) => {
		const formData: any = new FormData();

		formData.append("merchant_id", item.merchant_id);
		formData.append("name", item.name);
		formData.append("category", item.category);
		formData.append("description", item.description);

		for (let photo of files) {
			formData.append("files", photo);
		}

		try {
			const res = await createNewItem(formData);
			console.log("New Item created response", res);

			if (res.success) {
				// handle success
				resetForm();
			} else {
				// handle error
			}
		} catch (err) {
			console.log(err);
		}
		// Save new dish
		console.log("New dish created");
		// setOpen(false)
	};

	return (
		<>
			<ButtonToolbar>
				<AppButton onClick={() => setOpen(true)} label="Create New" />
			</ButtonToolbar>
			<Drawer
				size="28rem"
				backdrop={backdrop}
				open={open}
				onClose={() => setOpen(false)}
			>
				<Drawer.Header>
					<Drawer.Title>New Dish</Drawer.Title>
					<Drawer.Actions>
						<Group>
							<Button onClick={() => setOpen(false)}>Cancel</Button>
							<AppButton label="Create" onClick={startUpload} />
						</Group>
					</Drawer.Actions>
				</Drawer.Header>
				<Drawer.Body>
					<form>
						<Stack>
							<TextInput
								label="Dish Name"
								placeholder="What's your new dish called?"
								name="name"
								value={item.name}
								onChange={handleChange}
							/>
							<Select label="Category" value={item.category} />
							<Textarea
								minRows={5}
								rows={5}
								autosize
								name="description"
								label="Description"
								value={item.description}
								onChange={handleChange}
								placeholder="Write a description for your dish on its ingredients"
							/>

							<Uploader
								fileList={files}
								autoUpload={false}
								action="http://localhost:1000/api/v1/dishes"
								draggable
								onUpload={handleCreate}
								name="files"
								multiple
								onChange={setFiles}
								ref={uploader}
							>
								<div
									style={{
										height: 150,
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<FaCloudUploadAlt size={38} />
									<Text size="sm" c="dimmed" mt={6}>
										Click or Drag a picture of your new dish
									</Text>
								</div>
							</Uploader>
						</Stack>
					</form>
				</Drawer.Body>
			</Drawer>
		</>
	);
}

export default CreateNew;
