import React, { useEffect, useState } from "react";
import { Table } from "rsuite";
import CollaspedOutlineIcon from "@rsuite/icons/CollaspedOutline";
import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline";
import EmptyTable from "./EmptyTable";
import ToggleCell from "./customCells/ToggleCell";
import ActionCell from "./customCells/ActionCell";
import EditableCell from "./customCells/Editable";
import { isVisible } from "@testing-library/user-event/dist/utils";

const rowKey = "id";

const { Column, HeaderCell } = Table;

// const ExpandCell = ({
// 	rowData,
// 	dataKey,
// 	expandedRowKeys,
// 	onChange,
// 	...props
// }: any) => (
// 	<Cell {...props} style={{ padding: 5 }}>
// 		<IconButton
// 			appearance="subtle"
// 			onClick={() => {
// 				onChange(rowData);
// 			}}
// 			icon={
// 				expandedRowKeys.some((key: number) => key === rowData[rowKey]) ? (
// 					<CollaspedOutlineIcon />
// 				) : (
// 					<ExpandOutlineIcon />
// 				)
// 			}
// 		/>
// 	</Cell>
// );

const renderRowExpanded = (rowData: any) => {
	return (
		<div>
			<div
				style={{
					width: 60,
					height: 60,
					float: "left",
					marginRight: 10,
					background: "#eee",
				}}
			>
				<img src={rowData.avatar} style={{ width: 60 }} />
			</div>
			<p>Email: {rowData.email}</p>
			<p>Phone: {rowData.phone}</p>
		</div>
	);
};

const MOCK_DATA = [
	{
		id: 1,
		name: "Oysters On The Shell",
		description: "Half Dozen 24, Dozen 42",
		serving_amount: 2,
		isVisible: true,
	},
	{
		id: 2,
		name: "Chicken Tortilla Soup",
		description: "pico de gallo, avocado, crema, queso blanco",
		serving_amount: 0,
		isVisible: true,
	},
	{
		id: 3,
		name: "Truffled Brie Toast",
		description: "cranberry preserve, black truffle, honeycomb, chive",
		serving_amount: 0,
		isVisible: true,
	},
	{
		id: 4,
		name: "Jumbo Lump Crab Cake",
		description: "dhsadhsakfj djpaso jd",
		serving_amount: 0,
		isVisible: true,
	},
	{
		id: 5,
		name: "Short Rib Potstickers",
		description: "djsadas jgldkgjgk",
		serving_amount: 0,
		isVisible: true,
	},
];

function MenuTable({
	data,
	setData,
}: {
	data: any[];
	setData: (arr: any) => void;
}) {
	// const [data, setData] = useState<any[]>([]);
	const [expandedRowKeys, setExpandedRowKeys] = React.useState<any[]>([]);
	const [loading, setLoading] = useState(true);

	const handleItemToggle = (id: number, isChecked: boolean) => {
		const arr = data.map((item) => {
			if (item.id === id) {
				return { ...item, isVisible: isChecked };
			} else {
				return item;
			}
		});

		setData(arr);
	};

	const handleExpanded = (rowData: any, event: any) => {
		let open = false;
		const nextExpandedRowKeys = [];

		expandedRowKeys.forEach((key) => {
			if (key === rowData[rowKey]) {
				open = true;
			} else {
				nextExpandedRowKeys.push(key);
			}
		});

		if (!open) {
			nextExpandedRowKeys.push(rowData[rowKey]);
		}

		setExpandedRowKeys(nextExpandedRowKeys);
	};

	const handleChange = (id: number, key: string, value: any) => {
		const nextData: any = Object.assign([], data);
		nextData.find((item: any) => item.id === id)[key] = value;
		setData(nextData);
	};

	const handleEdit = (id: number) => {
		// const nextData = Object.assign([], data);
		// const activeItem: any = nextData.find((item: any) => item.id === id);
		// activeItem.status = activeItem.status ? null : "EDIT";
		// setData(nextData);
	};

	const handleDelete = (id: number) => {
		const arr = data.filter((item) => item.id !== id);
		setData(arr);
	};

	useEffect(() => {
		setLoading(true);

		if (data) {
			setLoading(false);
		}

		// setTimeout(() => {
		// 	setData(MOCK_DATA);
		// 	setLoading(false);
		// }, 500);
	}, []);

	return (
		<Table
			showHeader={true}
			style={{ marginTop: 40 }}
			renderEmpty={() => <EmptyTable isLoading={loading} />}
			shouldUpdateScroll={false} // Prevent the scrollbar from scrolling to the top after the table content area height changes.
			data={data}
			// rowKey={rowKey}
			autoHeight={true}
			minHeight={200}
			// expandedRowKeys={expandedRowKeys}
			onRowClick={handleExpanded}
			// renderRowExpanded={renderRowExpanded}
			loading={loading}
		>
			<Column flexGrow={1} fixed>
				<HeaderCell style={{ fontSize: 14 }}>Name</HeaderCell>
				<EditableCell
					dataKey="name"
					dataType="string"
					onChange={handleChange}
					onEdit={handleEdit}
				/>
			</Column>

			<Column align="right" flexGrow={1}>
				<HeaderCell style={{ fontSize: 14 }}>Description</HeaderCell>
				<EditableCell
					dataKey="description"
					dataType="string"
					onChange={handleChange}
					onEdit={handleEdit}
				/>
			</Column>

			<Column align="right" flexGrow={1} fixed>
				<HeaderCell style={{ fontSize: 14 }}>Serv. amount</HeaderCell>
				<EditableCell
					dataKey="serving_amount"
					dataType="number"
					onChange={handleChange}
					onEdit={handleEdit}
				/>
			</Column>

			<Column flexGrow={1} align="center">
				<HeaderCell style={{ fontSize: 14 }}>Availability</HeaderCell>
				<ToggleCell dataKey="display" toggle={handleItemToggle} />
			</Column>
			<Column flexGrow={1} align="center">
				<HeaderCell style={{ fontSize: 14 }}>Action</HeaderCell>
				<ActionCell
					dataKey="action"
					edit={handleEdit}
					deleteItem={handleDelete}
				/>
			</Column>
		</Table>
	);
}

export default MenuTable;
